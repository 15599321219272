import { type auto } from 'angular';
import { type ConfigFactory } from 'FrontRoyalConfig';
import { type RoleName, type CurrentUserIguanaObject } from 'Users';

type AdminRole = Extract<RoleName, 'admin' | 'interviewer' | 'super_editor'>;
const adminRoles: AdminRole[] = ['admin', 'interviewer', 'super_editor'];

const isUserAdmin = (role: RoleName): role is AdminRole => adminRoles.includes(role as AdminRole);

angular.module('Messaging', []).factory('ensureSendbirdToken', [
    '$injector',
    ($injector: auto.IInjectorService) => {
        const $rootScope = $injector.get('$rootScope');
        const $http = $injector.get('$http');
        const configFactory = $injector.get<ConfigFactory>('ConfigFactory');

        return {
            watchForTokenNeeded() {
                function fetchTokenIfNeeded(currentUser: CurrentUserIguanaObject) {
                    const config = configFactory.getSync(true);
                    if (config?.appEnvType() === 'production') return;
                    if (!currentUser) return;
                    if (currentUser.sendbird_access_token) return;

                    const isAdmin = isUserAdmin(currentUser.roleName());
                    const isStudentNetworkActivated = currentUser.programInclusions.some(
                        pi => !!pi.studentNetworkActivated,
                    );

                    if (!isAdmin && !isStudentNetworkActivated) return;

                    // this ends up adding the token to the user push messages so we don't need to wait for its response
                    $http.post(`${window.ENDPOINT_ROOT}/api/users/ensure_sendbird_user.json`, {});
                }

                $rootScope.$watchGroup(
                    [
                        'currentUser',
                        'currentUser.sendbird_access_token',
                        'currentUser.globalRole',
                        'currentUser.programInclusions',
                    ],
                    ([currentUser]) => {
                        fetchTokenIfNeeded(currentUser);
                    },
                );
            },
        };
    },
]);
