
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_dialog_content = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_dialog_content || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_dialog_content = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_dialog_content, ...{
    "before_we_begin": "Before we begin...",
    "like_to_ask_you": "We’d like to ask you 4 questions about your goals before we start your MBA application.",
    "lets_go": "Let's Go!"
}
}
    