
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_start = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_start || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_start = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_start, ...{
    "skip": "Skip",
    "most_selective": "Where Education<br/ >Meets Opportunity",
    "zero_dollars": "$0",
    "fast_track": "Fast track your career.",
    "with_our_mba": "With our MBA, Executive MBA and related career network, you'll improve both job prospects and earning power.",
    "learn_fast": "Learn fast. Anywhere. Anytime.",
    "busy_but_motivated": "You're busy, but motivated. Try our mobile-first degree programs and earn your MBA in months, not years.",
    "land_dream_job": "Land your dream job.",
    "connect_alumni_employers": "In our career network you'll connect with peers, alumni and potential employers looking to hire.",
    "radically_affordable": "Radically Affordable",
    "sponsored_tuition_model": "Our Sponsored Tuition model lowers student costs dramatically vs traditional MBAs, sometimes all the way to $0.",
    "get_started": "Get Started",
    "already_have_an_account": "Already have an account? <a ng-click=\"loadRoute('/onboarding/hybrid/login?previous=start')\">Login</a>"
}
}
    