
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_login = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_login || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_login = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_login, ...{
    "get_started": "Don't have an account?&nbsp;<span class='bolded-text'>Get Started</span>"
}
}
    