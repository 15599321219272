
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_register = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_register || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_register = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_register, ...{
    "header_text": " <p>Great!</p><p>Let's set up your account.</p>",
    "header_text_miya_miya": "<p>Welcome!</p><p>Let's set up your account.</p>",
    "already_have_an_account": "Already have an account?&nbsp;<span class='bolded-text'>Login</span>"
}
}
    