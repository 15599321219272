
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_login = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_login || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_login = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_login, ...{
    "login": "Login to <strong>{{brandName}}</strong>",
    "do_not_have_an_account": "Don't have an account?",
    "get_started": "Get Started",
    "icp_file_number": "Shanghai ICP No. 2021026217-2",
    "security_filing_number": "Shanghai Public Network Security No. 31010602006704"
}
}
    