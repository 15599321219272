
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_forgot_password = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_forgot_password || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_forgot_password = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_forgot_password, ...{
    "return_to_login": "<span>Return to</span><a ng-click='goToLogin()'>&nbsp;Login</a>"
}
}
    