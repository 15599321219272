import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import { setupBrandNameProperties } from 'AppBranding';
import template from 'Onboarding/angularModule/views/onboarding.hybrid.login.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import logoSmall from 'images/onboarding/Logo-Small@2x.png';
import logoSmallQuantic from 'vectors/onboarding/quantic-logo-vert-color.svg';
import miyaMiyaLogo from 'images/miyamiya/miyamiya-logo.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingHybridLogin', [
    '$injector',
    function factory($injector) {
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const $routeParams = $injector.get('$routeParams');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                const config = ConfigFactory.getSync();
                setupBrandNameProperties($injector, scope, { config });

                const isQuantic = config.isQuantic();

                // Normally we would use the `setupBrandScopeProperties` method from the `AppBrandMixin` to
                // setup this scope property, but because of the Miya Miya app we special case the custom
                // handling here rather than generalizing it inside of the `setupBrandScopeProperties` method
                // in the `AppBrandMixin` for fear of messing things up in other areas of the app.
                Object.defineProperty(scope, 'logoSmall', {
                    get() {
                        if ($window.CORDOVA?.miyaMiya) {
                            return miyaMiyaLogo;
                        }
                        if (isQuantic) {
                            return logoSmallQuantic;
                        }
                        return logoSmall;
                    },
                });

                scope.logoWidth = $window.CORDOVA?.miyaMiya ? 86 : 50;
                scope.logoHeight = $window.CORDOVA?.miyaMiya ? 20 : 50;

                scope.showBack = _.includes(['register', 'start'], $routeParams.previous);

                scope.goBack = () => {
                    const whereToGo =
                        $routeParams.previous === 'start'
                            ? '/onboarding/hybrid?finished'
                            : '/onboarding/hybrid/register';
                    scope.loadRoute(whereToGo);
                };
            },
        };
    },
]);
