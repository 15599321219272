import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';

angularModule.factory('OnboardingHelperMixin', [
    '$injector',
    $injector => {
        const ClientStorage = $injector.get('ClientStorage');

        return {
            onLink(scope) {
                scope.storeAnswer = (questionText, answer) => {
                    const key = 'onboardingQuestions';

                    const questionsString = ClientStorage.getItem(key);
                    let questions = {};
                    if (questionsString) {
                        questions = JSON.parse(questionsString);
                    }

                    questions[questionText] = answer;
                    ClientStorage.setItem(key, JSON.stringify(questions));
                };
            },
        };
    },
]);
