import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import template from 'Onboarding/angularModule/views/onboarding_modal_dialog.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingModal', [
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            transclude: true,
        };
    },
]);
