import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import template from 'Onboarding/angularModule/views/onboarding_forgot_password.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingForgotPassword', [
    '$injector',
    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const scopeTimeout = $injector.get('scopeTimeout');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                NavigationHelperMixin.onLink(scope);

                scope.step = 4;
                scope.ready = false;

                scopeTimeout(
                    scope,
                    () => {
                        scope.ready = true;
                    },
                    10,
                );

                scope.isReady = () => scope.ready && scope.step === 4;

                scope.goToLogin = () => {
                    scope.ready = false;
                    scopeTimeout(
                        scope,
                        () => {
                            scope.loadRoute('/sign-in');
                        },
                        500,
                    );
                };
            },
        };
    },
]);
