import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import { gsap } from 'FrontRoyalGsap';

angularModule.animation('.onboarding-animation-0', [
    () => ({
        leave(element, onComplete) {
            const cloudsLeft = element.find('#clouds-left');
            const cloudsRight = element.find('#clouds-right');
            const mountain = element.find('#illustration-mountain');
            const snow = element.find('#snow');
            const sun = element.find('#brown-circle');
            const timeline = gsap.timeline();

            timeline
                .to(cloudsLeft, {
                    duration: 0.7,
                    x: -1000,
                    autoAlpha: 0,
                })
                .to(
                    cloudsRight,
                    {
                        duration: 0.7,
                        x: 1000,
                        autoAlpha: 0,
                    },
                    '-=0.7',
                )
                .to(
                    mountain,
                    {
                        duration: 0.7,
                        y: 400,
                        autoAlpha: 0,
                    },
                    '-=0.7',
                )
                .to(
                    sun,
                    {
                        duration: 0.7,
                        y: 300,
                        autoAlpha: 0,
                    },
                    '-=0.6',
                )
                .to(
                    snow,
                    0.7,
                    {
                        y: -300,
                        autoAlpha: 0,
                    },
                    '-=0.7',
                )
                .eventCallback('onComplete', onComplete);
        },
    }),
]);

angularModule.animation('.onboarding_modal__animated', [
    () => ({
        enter(element, onComplete) {
            const timeline = gsap.timeline();

            timeline
                .fromTo(
                    element,
                    {
                        autoAlpha: 0,
                        transform: 'matrix(1,0,0,1,0,100)',
                    },
                    {
                        duration: 0.6,
                        autoAlpha: 1,
                        transform: 'matrix(1,0,0,1,0,0)',
                    },
                )
                .eventCallback('onComplete', onComplete);
        },

        leave(element, onComplete) {
            const timeline = gsap.timeline();

            timeline
                .fromTo(
                    element,
                    {
                        autoAlpha: 1,
                        transform: 'matrix(1,0,0,1,0,0)',
                    },
                    {
                        duration: 0.6,
                        autoAlpha: 0,
                        transform: 'matrix(1,0,0,1,0,100)',
                    },
                )
                .eventCallback('onComplete', onComplete);
        },
    }),
]);

angularModule.animation('.onboarding_question', [
    () => ({
        enter(element, onComplete) {
            const timeline = gsap.timeline();

            timeline
                .from(
                    element,
                    {
                        duration: 0.7,
                        autoAlpha: 0,
                    },
                    0.8,
                )
                .eventCallback('onComplete', onComplete);
        },

        leave(element, onComplete) {
            const timeline = gsap.timeline();

            timeline
                .to(element, {
                    duration: 0.4,
                    autoAlpha: 0,
                })
                .eventCallback('onComplete', onComplete);
        },
    }),
]);

/**
 * Animation constants.
 */

const enterDuration = 0.6;
const enterPhase = enterDuration / 2;
const initialDelay = enterDuration + 0.2;
const leaveDuration = 0.3;
const leavePhase = leaveDuration / 2;
const verticalOffset = 350;

/**
 * Animations.
 */

const animations = [
    {
        name: '.onboarding-animation-1',
        images: ['#puzzle-1', '#puzzle-2', '#puzzle-3', '#puzzle-4'],
    },
    {
        name: '.onboarding-animation-2',
        images: ['#illustration-megaphone', '#dollar-pattern-1', '#dollar-pattern-2', '#dollar-pattern-3'],
    },
    {
        name: '.onboarding-animation-3',
        images: ['#illustration-rocket', '#rocket-pattern-1', '#rocket-pattern-2', '#rocket-pattern-3'],
    },
    {
        name: '.onboarding-animation-5',
        images: ['#illustration-table', '#illustration-application'],
    },
];

/**
 * Animations by name.
 */

animations.forEach(animation => {
    angularModule.animation(animation.name, [
        () => ({
            enter(element, onComplete) {
                const timeline = gsap.timeline();

                animation.images.forEach((image, index) => {
                    timeline.from(
                        element.find(image),
                        {
                            duration: enterDuration,
                            autoAlpha: 0,
                            y: verticalOffset,
                        },
                        index > 0 ? `-=${enterPhase}` : initialDelay,
                    );
                });

                timeline.eventCallback('onComplete', onComplete);
            },

            leave(element, onComplete) {
                const timeline = gsap.timeline();

                animation.images
                    .concat([])
                    .reverse()
                    .forEach((image, index) => {
                        timeline.to(
                            element.find(image),
                            {
                                duration: leaveDuration,
                                autoAlpha: 0,
                                y: -verticalOffset,
                            },
                            index > 0 && `-=${leavePhase}`,
                        );
                    });

                timeline.eventCallback('onComplete', onComplete);
            },
        }),
    ]);
});
