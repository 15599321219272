import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import { setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import template from 'Onboarding/angularModule/views/onboarding.hybrid.start.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { gsap } from 'FrontRoyalGsap';

import onboardingHexBlue_2x from 'images/onboarding/Hex-Blue@2x.png';
import onboardingLights_2x from 'images/onboarding/Lights@2x.png';
import onboardingMbaCertificate_2x from 'images/onboarding/MBA-Certificate@2x.png';
import onboardingHexOrange_2x from 'images/onboarding/Hex-Orange@2x.png';
import onboardingIphone_2x from 'images/onboarding/iphone@2x.png';
import onboardingProfile1_2x from 'images/onboarding/Profile-1@2x.png';
import onboardingHexYellow_2x from 'images/onboarding/Hex-Yellow@2x.png';
import onboardingProfile2_2x from 'images/onboarding/Profile-2@2x.png';
import onboardingProfile3_2x from 'images/onboarding/Profile-3@2x.png';
import onboardingProfile4_2x from 'images/onboarding/Profile-4@2x.png';
import onboardingProfile5_2x from 'images/onboarding/Profile-5@2x.png';
import onboardingProfile6_2x from 'images/onboarding/Profile-6@2x.png';
import onboardingProfile7_2x from 'images/onboarding/Profile-7@2x.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingHybridStart', [
    '$injector',
    function factory($injector) {
        const $window = $injector.get('$window');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ClientStorage = $injector.get('ClientStorage');
        const $routeParams = $injector.get('$routeParams');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                scope.onboardingHexBlue_2x = onboardingHexBlue_2x;
                scope.onboardingLights_2x = onboardingLights_2x;
                scope.onboardingMbaCertificate_2x = onboardingMbaCertificate_2x;
                scope.onboardingHexOrange_2x = onboardingHexOrange_2x;
                scope.onboardingIphone_2x = onboardingIphone_2x;
                scope.onboardingProfile1_2x = onboardingProfile1_2x;
                scope.onboardingHexYellow_2x = onboardingHexYellow_2x;
                scope.onboardingProfile2_2x = onboardingProfile2_2x;
                scope.onboardingProfile3_2x = onboardingProfile3_2x;
                scope.onboardingProfile4_2x = onboardingProfile4_2x;
                scope.onboardingProfile5_2x = onboardingProfile5_2x;
                scope.onboardingProfile6_2x = onboardingProfile6_2x;
                scope.onboardingProfile7_2x = onboardingProfile7_2x;

                NavigationHelperMixin.onLink(scope);

                const config = ConfigFactory.getSync();
                setupBrandNameProperties($injector, scope, { config });
                setupBrandScopeProperties($injector, scope, ['onboardingHybridStartLogo', 'onboardingHybridStartHex'], {
                    config,
                });

                if (ClientStorage.getItem('skip_onboarding')) {
                    scope.loadRoute('/onboarding/hybrid/login');
                }

                const slider = {
                    el: {
                        slider: $('#slider'),
                        holder: $('.holder'),
                        imgSlide: $('.slide-image'),
                        skipButton: $('#skip_button'),
                    },
                    slideWidth: $('#slider').width(),
                    touchstartx: undefined,
                    touchmovex: undefined,
                    movex: undefined,
                    index: 0,
                    longTouch: undefined,
                    totalPages: $('.holder').children().length,
                    flickTreshold: 60,
                    timelines: {},
                    init() {
                        this.bootstrapTimelines();
                        this.pauseTimelines();

                        this.bindUIEvents();
                        this.animLoop();

                        if ($routeParams.finished) {
                            this.skip();
                        }
                    },
                    animLoop() {
                        window.requestAnimationFrame(slider.animLoop);
                        slider.render();
                    },
                    render() {
                        let left = Math.abs($(slider.el.holder).css('transform').split(',')[4]);
                        if ($window.isNaN(left)) {
                            left = 0;
                        }
                        const lastStop = (slider.totalPages - 1) * slider.slideWidth;
                        const percent = left / lastStop;

                        Object.keys(slider.timelines).forEach(timeline => {
                            slider.timelines[timeline].pause();
                            slider.timelines[timeline].progress(percent, true);
                        });

                        if (slider.index > 0) {
                            $('#nav').removeClass('__first-page');
                        } else {
                            $('#nav').addClass('__first-page');
                        }
                        $('#nav .nav__item').each(function (index) {
                            if (index === slider.index) {
                                $(this).addClass('nav__item--active');
                            } else {
                                $(this).removeClass('nav__item--active');
                            }
                        });
                    },

                    bindUIEvents() {
                        this.el.holder.on('touchstart', event => {
                            slider.start(event);
                        });
                        this.el.holder.on('touchmove', event => {
                            slider.move(event);
                            // slider.render();
                        });
                        this.el.holder.on('touchend', event => {
                            slider.end(event);
                        });

                        this.el.skipButton.on('click', () => {
                            slider.skip();
                        });
                    },
                    skip() {
                        this.index = this.totalPages - 1;
                        this.el.holder
                            .removeClass('animate')
                            .css('transform', `translate3d(-${this.index * this.slideWidth}px,0,0)`);
                        this.el.imgSlide
                            .removeClass('animate')
                            .css('transform', `translate3d(-${this.index * this.slideWidth}px,0,0)`);
                        this.movex = this.index * this.slideWidth;
                    },
                    start(event) {
                        const self = this;

                        // Test for flick.
                        self.longTouch = false;
                        setTimeout(() => {
                            self.longTouch = true;
                        }, 250);

                        // Get the original touch position.
                        self.touchstartx = event.originalEvent.touches[0].pageX;

                        // The movement gets all janky if there's a transition on the elements.
                        $('.animate').removeClass('animate');
                    },

                    move(event) {
                        // Continuously return touch position.
                        this.touchmovex = event.originalEvent.touches[0].pageX;
                        // Calculate distance to translate holder.
                        this.movex = this.index * this.slideWidth + (this.touchstartx - this.touchmovex);
                        // Defines the speed the images should move at.
                        const panx = 100 - this.movex / (this.totalPages * 2);
                        if (this.movex < (this.totalPages - 1) * this.slideWidth) {
                            // Makes the holder stop moving when there is no more content.
                            this.el.holder.css('transform', `translate3d(-${this.movex}px,0,0)`);
                        }
                        if (panx < 100) {
                            // Corrects an edge-case problem where the background image moves without the container moving.
                            this.el.imgSlide.css('transform', `translate3d(-${panx}px,0,0)`);
                        }
                    },

                    end() {
                        // Calculate the distance swiped.
                        const absMove = Math.abs(this.index * this.slideWidth - this.movex);
                        // Calculate the index. All other calculations are based on the index.
                        if (
                            absMove > this.slideWidth / 2 ||
                            (this.longTouch === false && absMove > this.flickTreshold)
                        ) {
                            if (this.movex > this.index * this.slideWidth && this.index < this.totalPages - 1) {
                                this.index += 1;
                            } else if (this.movex < this.index * this.slideWidth && this.index > 0) {
                                this.index -= 1;
                            }
                        }
                        // Move and animate the elements.
                        this.el.holder
                            .addClass('animate')
                            .css('transform', `translate3d(-${this.index * this.slideWidth}px,0,0)`);
                        this.el.imgSlide
                            .addClass('animate')
                            .css('transform', `translate3d(-${this.index * this.slideWidth}px,0,0)`);

                        // mlangston - recalibrate movex
                        // Not setting this was causing a click or tap (not a slide movement) to change the screen AFTER an initial slide movement
                        // was done. This is because of the above if statement that checks if this.movex < this.index * this.slideWidth. By
                        // recalibrating movex to the new slideWidth after this end function is done we fix the issue, so nothing happens
                        // now on click / tap.
                        this.movex = this.index * this.slideWidth;
                    },
                    bootstrapTimelines() {
                        slider.timelines.certificate = gsap.timeline();
                        slider.timelines.lights = gsap.timeline();
                        slider.timelines.iphone = gsap.timeline();
                        slider.timelines.iphonePicture = gsap.timeline();
                        slider.timelines.hexagonPictures = gsap.timeline();
                        slider.timelines.noCost = gsap.timeline();
                        slider.timelines.skipButton = gsap.timeline();
                        slider.timelines.navigation = gsap.timeline();

                        gsap.set('.lights, .iphone', {
                            x: '-50%',
                        });

                        slider.timelines.certificate.set('#certificate', {
                            scale: 0.01,
                            opacity: 1,
                        });

                        slider.timelines.certificate
                            .to('#certificate', { duration: 1.6 })
                            .to('#certificate', {
                                duration: 1,
                                scale: 1,
                                opacity: 1,
                            })
                            .to('#certificate', {
                                duration: 2.45,
                                scale: 0.55,
                                x: `+=${window.innerWidth}`,
                                y: 25,
                            })
                            .to('#certificate', {
                                duration: 2.39,
                                scale: 0.85,
                                y: 2.5,
                                x: `+=${window.innerWidth}`,
                            })
                            .to('#certificate', {
                                duration: 2.46,
                                x: `+=${window.innerWidth}`,
                                scale: 1.15,
                            });

                        slider.timelines.lights
                            .to('.lights', {
                                duration: 1,
                                scale: 0.1,
                            })
                            .to('.lights', {
                                duration: 1.6,
                                scale: 1,
                            })
                            .to('.lights', {
                                duration: 1.4,
                                display: 'none',
                            })
                            .to('.lights', { duration: 6 });

                        slider.timelines.iphone.set('.iphone', {
                            display: 'none',
                        });
                        slider.timelines.iphone
                            .to('.iphone', {
                                duration: 2.1,
                                display: 'none',
                            })
                            .to('.iphone', {
                                duration: 0.1,
                                display: 'inline-block',
                            })
                            .to('.iphone', {
                                duration: 1,
                                x: window.innerWidth,
                            })
                            .to('.iphone', {
                                duration: 1.8,
                                x: '-50%',
                            })
                            .to('.iphone', {
                                duration: 5,
                                display: 'none',
                                x: -window.innerWidth,
                            });

                        slider.timelines.iphonePicture.set('.iphone_picture', {
                            scale: 0,
                        });
                        slider.timelines.iphonePicture
                            .from('.iphone_picture', { duration: 2.2 })
                            .to('.iphone_picture', {
                                duration: 1,
                                x: window.innerWidth,
                            })
                            .to('.iphone_picture', {
                                duration: 1.8,
                                x: 0,
                            })
                            .to(
                                '.iphone_picture',
                                {
                                    duration: 0.5,
                                    scale: 1,
                                },
                                '-=0.7',
                            )
                            .to('.iphone_picture', {
                                duration: 5,
                                x: -window.innerWidth,
                            });

                        slider.timelines.hexagonPictures.set('#staggered img', {
                            scale: 0,
                            display: 'none',
                            // opacity:0
                        });

                        slider.timelines.hexagonPictures
                            .to('#staggered img', {
                                duration: 6.7,
                                scale: 0,
                            })
                            .to('#staggered img', {
                                duration: 0.1,
                                display: 'inline-block',
                            })
                            .to('#staggered img', {
                                duration: 0.8,
                                scale: 1,
                                stagger: 0.1333333,
                            })
                            .to('#staggered img', { duration: 2.5 });

                        slider.timelines.noCost
                            .to('.no_cost', {
                                duration: 9,
                                x: window.innerWidth * 4,
                                display: 'none',
                            })
                            .to('.no_cost', {
                                duration: 1,
                                x: window.innerWidth * 3,
                                display: 'inline-block',
                            });

                        slider.timelines.skipButton.set('#skip_button', {
                            color: '#ffffff',
                            opacity: 1,
                        });

                        slider.timelines.skipButton
                            .to('#skip_button', {
                                duration: 2,
                                opacity: 0.2,
                                color: '#000000',
                            })
                            .to('#skip_button', {
                                duration: 6,
                                y: 0,
                            })
                            .to('#skip_button', {
                                duration: 2,
                                y: -50,
                            });

                        slider.timelines.navigation
                            .from('#nav', {
                                duration: 8,
                                y: 0,
                            })
                            .to('#nav', {
                                duration: 2,
                                y: 100,
                            });
                    },
                    pauseTimelines() {
                        Object.keys(slider.timelines).forEach(timeline => {
                            slider.timelines[timeline].pause();
                        });
                    },
                };
                slider.init();
            },
        };
    },
]);
