
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding, ...{
    "question_1": "Are you interested in getting promoted <br> or making a career move?",
    "question_2": "Do you want to develop your team-building and leadership skills?",
    "question_3": "Are you looking to become more <br> marketable and earn a higher salary?",
    "question_4": "Do you want to gain the tools and confidence to start your own business?"
}
}
    