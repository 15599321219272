import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import template from 'Onboarding/angularModule/views/onboarding.html';
import onboardingDialogContentTemplate from 'Onboarding/angularModule/views/onboarding_dialog_content.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);
const onboardingDialogContentTemplateUrl = cacheAngularTemplate(angularModule, onboardingDialogContentTemplate);

angularModule.directive('onboarding', [
    '$injector',
    function factory($injector) {
        const $animate = $injector.get('$animate');
        const scopeTimeout = $injector.get('scopeTimeout');
        const Modal = $injector.get('Onboarding.Modal');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const OnboardingHelperMixin = $injector.get('OnboardingHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                // Setup localization keys
                const translationHelper = new TranslationHelper('onboarding.onboarding');

                NavigationHelperMixin.onLink(scope);
                OnboardingHelperMixin.onLink(scope);

                scopeTimeout(
                    scope,
                    () => {
                        Modal.show({
                            container: 'onboarding',
                            controller: [
                                'modalInstance',
                                function (modalInstance) {
                                    this.close = () => modalInstance.hide();
                                },
                            ],
                            controllerAs: 'onBoardingDialog',
                            templateUrl: onboardingDialogContentTemplateUrl,
                        });
                    },
                    2,
                );

                scope.currentQuestion = 0;
                scope.preventClick = false;

                scope.questions = [
                    translationHelper.get('question_1'),
                    translationHelper.get('question_2'),
                    translationHelper.get('question_3'),
                    translationHelper.get('question_4'),
                ];

                scope.getNextQuestion = currentAnswer => {
                    if (scope.preventClick) {
                        return;
                    }

                    // prevent rapid/double clicks on buttons during transition
                    scope.preventClick = true;
                    scopeTimeout(
                        scope,
                        () => {
                            scope.preventClick = false;
                        },
                        800,
                    );

                    scope.storeAnswer(scope.questions[scope.currentQuestion], currentAnswer);

                    scope.currentQuestion += 1;

                    if (scope.currentQuestion !== scope.questions.length) {
                        return;
                    }

                    const headerContent = angular.element('onboarding-animated-header > div');

                    $animate.on('leave', headerContent, (element, phase) => {
                        if (phase !== 'close') {
                            return;
                        }

                        scopeTimeout(
                            scope,
                            () => {
                                scope.loadRoute('/register');
                            },
                            500,
                        );
                    });
                };
            },
        };
    },
]);
