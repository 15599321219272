import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import { setupStyleHelpers } from 'AppBranding';
import template from 'Onboarding/angularModule/views/onboarding.hybrid.questionary.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { gsap } from 'FrontRoyalGsap';

import illustrationOne from 'images/onboarding/Illustration-1@2x.png';
import illustrationTwo from 'images/onboarding/Illustration-2@2x.png';
import illustrationThree from 'images/onboarding/Illustration-3@2x.png';
import illustrationFour from 'images/onboarding/Illustration-4@2x.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingHybridQuestionary', [
    '$injector',
    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const OnboardingHelperMixin = $injector.get('OnboardingHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const scopeTimeout = $injector.get('scopeTimeout');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                OnboardingHelperMixin.onLink(scope);
                setupStyleHelpers($injector, scope, { config: ConfigFactory.getSync() });

                //---------------------------
                // Initialization
                //---------------------------

                // Setup localization keys
                const translationHelper = new TranslationHelper('onboarding.onboarding_hybrid_questionary');

                scope.questions = [
                    {
                        image: illustrationOne,
                        question: translationHelper.get('question_1'),
                    },
                    {
                        image: illustrationTwo,
                        question: translationHelper.get('question_2'),
                    },
                    {
                        image: illustrationThree,
                        question: translationHelper.get('question_3'),
                    },
                    {
                        image: illustrationFour,
                        question: translationHelper.get('question_4'),
                    },
                ];
                const totalOfQuestions = scope.questions.length;
                const answers = [];

                // angular-swing flips the cards from bottom to top
                scope.questions.reverse();

                /*
                 * @name throwout - the default angular-swing throw out function
                 */
                scope.throwout = (index, eventName, eventObject) => {
                    scope.questions.splice(index, 1);
                    eventObject.target.remove();
                    answers.push(eventObject.throwDirection > 0 ? 'yes' : 'no');
                    if (answers.length === totalOfQuestions) {
                        scope.transitionToRegister();
                    }
                };

                /*
                 * @name throwCard
                 * @desc attached to the 'no' and 'yes' buttons ng-click
                 * @param {int} index - the card index
                 * @param {string} direction - the direction to animate the card to
                 * since angular-swing doesn't wrap card.throwOut , we'll have to animate it and cut it out ourselves
                 */
                scope.throwCard = (index, direction, answer) => {
                    scope.storeAnswer(scope.questions[index], answer);

                    const card = document.querySelectorAll('.questionary__cards__wrapper ul li')[index];

                    if (!card) {
                        return;
                    }

                    const distance = direction === 'left' ? -window.innerWidth : window.innerWidth;
                    const rotation = direction === 'left' ? -45 : 45;

                    /*
                        There's a delay with :active due to angular-swing capturing
                        all of the card touch events, so we add an .active class to the button
                        to produce the same output
                    */
                    if (direction === 'right') {
                        $('.questionary__cards__wrapper ul li:last-of-type button:last-of-type').addClass('active');
                    } else {
                        $('.questionary__cards__wrapper ul li:last-of-type button:first-of-type').addClass('active');
                    }

                    gsap.to(card, {
                        duration: 0.4,
                        x: distance,
                        y: -200,
                        opacity: 0,
                        rotation,
                        ease: 'power1.inOut',
                        onCompleteParams: [card],
                        onComplete(_card) {
                            if (_card) {
                                _card.remove();
                            }
                            answers.push(direction === 'right' ? 'yes' : 'no');
                            if (answers.length === totalOfQuestions) {
                                scope.transitionToRegister();
                            }
                        },
                    });
                };

                // angular-swing card drag callback

                scope.dragmove = (eventName, eventObject) => {
                    if (eventObject.throwOutConfidence > 0) {
                        if (eventObject.throwDirection === 1) {
                            // swipes card to right (no)
                            $(eventObject.target.querySelectorAll('button:last-of-type')).addClass('pressed');
                            $(eventObject.target.querySelectorAll('button:last-of-type')).css({
                                'background-color': `rgba(84, 222, 111,${eventObject.throwOutConfidence * 3})`,
                                color: 'white',
                            });

                            // reset the other button in case the user goes the oposite direction
                            $(eventObject.target.querySelectorAll('button:first-of-type')).css({
                                color: '#b5b5b5',
                                'background-color': '#f9f9f9',
                            });
                        } else if (eventObject.throwDirection === -1) {
                            // swipes card to left (yes)
                            // fade colors as the user drags more and more to the left
                            $(eventObject.target.querySelectorAll('button:first-of-type')).addClass('pressed');
                            $(eventObject.target.querySelectorAll('button:first-of-type')).css({
                                'background-color': `rgba(255, 77, 99,${eventObject.throwOutConfidence * 3})`,
                                color: 'white',
                            });

                            // reset the other button in case the user goes the oposite direction
                            $(eventObject.target.querySelectorAll('button:last-of-type')).css({
                                color: '#b5b5b5',
                                'background-color': '#f9f9f9',
                            });
                        }
                    }
                };

                // angular-swing card throwin callback
                scope.throwin = (eventName, eventObject) => {
                    // revert button styles to the default
                    $(eventObject.target.querySelectorAll('button')).removeClass('pressed');
                    $(eventObject.target.querySelectorAll('button')).css({
                        'background-color': '#f9f9f9',
                        color: '#b5b5b5',
                    });
                };

                // angular-swing options
                scope.options = {
                    throwOutConfidence(offset, element) {
                        return Math.min(Math.abs(offset) / element.offsetWidth, 1);
                    },
                    isThrowOut(offset, elementWidth, throwOutConfidence) {
                        return throwOutConfidence >= 0.5;
                    },
                };

                scope.startInitialAnimations = () => {
                    const logo = document.querySelectorAll('.hybrid__header__logo');
                    const balloon = document.querySelectorAll('.hybrid__header__message');
                    const balloonText = document.querySelectorAll('.hybrid__header__message__text');
                    const balloonTextParagraph = document.querySelectorAll('.hybrid__header__message__text p');
                    const loading = document.querySelectorAll('.hybrid__header__loading');
                    const cardsWrapper = document.querySelectorAll('.questionary__cards__wrapper');

                    gsap.set(balloonTextParagraph, {
                        display: 'none',
                        opacity: 0,
                    });

                    gsap.set(logo, {
                        scale: 0,
                    });
                    gsap.set(balloon, {
                        opacity: 0,
                        width: '70px',
                    });
                    gsap.set(balloonText, {
                        display: 'none',
                        opacity: 0,
                    });
                    gsap.set(balloonTextParagraph, {
                        display: 'none',
                        opacity: 0,
                    });
                    gsap.set(loading, {
                        opacity: 0,
                    });
                    gsap.set(cardsWrapper, {
                        opacity: 0,
                        scale: 0.4,
                        y: 300,
                    });

                    scopeTimeout(scope, () => {
                        const timeline = gsap.timeline();

                        timeline
                            .to(logo, {
                                duration: 0.2,
                                delay: 1,
                                scale: 1,
                            })
                            .to(balloon, {
                                duration: 0.2,
                                opacity: 1,
                            })
                            .to(
                                loading,
                                {
                                    duration: 0.2,
                                    opacity: 1,
                                },
                                '-=0.2',
                            )
                            .to(loading, {
                                duration: 0.2,
                                opacity: 0,
                                display: 'none',
                            })
                            .to(balloon, {
                                duration: 0.2,
                                width: '237px',
                            })
                            .to(balloonTextParagraph, {
                                duration: 0.2,
                                opacity: 1,
                                display: 'inline-block',
                            })
                            .to(
                                balloonText,
                                {
                                    duration: 0.5,
                                    opacity: 1,
                                    display: 'inline-block',
                                },
                                '-=0.25',
                            )
                            .to(cardsWrapper, {
                                duration: 0.4,
                                scale: 1,
                                opacity: 1,
                                y: 0,
                            })
                            .to(
                                cardsWrapper,
                                {
                                    duration: 0.4,
                                    scale: 1,
                                    ease: 'power1.inOut',
                                },
                                '-=0.4',
                            );
                    });
                };

                scope.startInitialAnimations();

                scope.transitionToRegister = () => {
                    const timeline = gsap.timeline();
                    timeline
                        .to(document.querySelectorAll('.hybrid__header__message__text'), {
                            duration: 0.1,
                            opacity: 0,
                            display: 'none',
                        })
                        .to(document.querySelectorAll('.hybrid__header__message'), {
                            duration: 0.2,
                            width: 70,
                        })
                        .to(document.querySelectorAll('.hybrid__header__loading'), {
                            duration: 0.1,
                            opacity: 1,
                            display: 'inline-block',
                            onComplete() {
                                scope.loadRoute('/onboarding/hybrid/register?animate');
                            },
                        });
                };
            },
        };
    },
]);
