import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import { AppBrandConfigs, setupBrandNameProperties } from 'AppBranding';
import { DEFAULT_SIGNUP_LOCATION } from 'SignupLocations';
import template from 'Onboarding/angularModule/views/onboarding_login.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

import logoSecurityFilingNumber from 'images/logo-security-filing-number.png';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingLogin', [
    '$injector',
    function factory($injector) {
        const scopeTimeout = $injector.get('scopeTimeout');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $location = $injector.get('$location');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                const config = ConfigFactory.getSync();

                scope.logoSecurityFilingNumber = logoSecurityFilingNumber;
                scope.chinaRegionMode = config.chinaRegionMode();

                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope, { config });
                ensureCorrectSignInPageForInstitution();

                scopeTimeout(
                    scope,
                    () => {
                        scope.ready = true;
                    },
                    10,
                );

                // Initial settings.
                scope.step = 4;
                scope.ready = false;

                scope.isReady = () => scope.ready && scope.step === 4;

                scope.goToPageJoinPage = () => {
                    scope.loadUrl(DEFAULT_SIGNUP_LOCATION);
                };

                // if a user is redirected to /sign-in with `?institution_id=XXX` query param,
                // we will redirect them to the properly branded sign-in page for their institution
                function ensureCorrectSignInPageForInstitution() {
                    const institutionId = $location.search().institution_id;
                    if (!institutionId) {
                        return;
                    }
                    const correctBrandConfig = AppBrandConfigs[institutionId] || AppBrandConfigs.quantic;
                    if (correctBrandConfig.brandNameShort !== scope.brandNameShort) {
                        scope.gotoSignIn(true, correctBrandConfig.urlPrefix);
                    }
                }
            },
        };
    },
]);
