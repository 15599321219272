import angularModule from 'MobileAppRateHelper/angularModule/scripts/mobile_app_rate_helper_module';
import moment from 'moment-timezone';
import { targetBrandConfig } from 'AppBranding';

angularModule.factory('MobileAppRateHelper', [
    '$injector',
    $injector => {
        const $q = $injector.get('$q');
        const $window = $injector.get('$window');

        return {
            APP_RATING_PROMPT_COUNTER: 'app_rating_prompt_counter',
            LAST_APP_RATING_PROMPT_AT: 'last_app_rating_prompt_at',
            MAX_NUM_APP_RATING_PROMPTS: 3,

            supportsNativeStorage() {
                return !!($window.CORDOVA && $window.NativeStorage);
            },

            // See https://trello.com/c/JqfEZzSm and https://trello.com/c/E64eHApu for more information
            meetsRequirementsForMobileAppRatingPrompt(user) {
                // There are certain states the user can be when we don’t want to prompt them for an app rating,
                // so we check for those first since they don’t require an API request.
                if (
                    !this.supportsNativeStorage() ||
                    !user ||
                    !targetBrandConfig(user).supportsMobileAppRateHelper ||
                    user.hasExternalInstitution ||
                    user.isDemo ||
                    !user.hasEverApplied ||
                    (user.hasEverBeenRejected && !user.isCurrentOrHasCompletedActiveProgram)
                ) {
                    return $q.when(false);
                }

                // This last step houses all of the asynchronous checks (some of which may require an API call),
                // which is why this step is separated from the rest and done last.
                return $q
                    .all([
                        user.progress.getAllProgress(),
                        this.getAppRatingPromptCounter(),
                        this.getLastAppRatingPromptAt(),
                    ])
                    .then(responses => {
                        const streamProgresses = responses[0].streamProgress;
                        const completeStream = _.find(streamProgresses, streamProgress => streamProgress.complete);
                        const appRatingPromptCounter = responses[1];
                        const lastAppRatingPromptAt = responses[2];
                        return (
                            !!completeStream &&
                            appRatingPromptCounter < 3 &&
                            (!lastAppRatingPromptAt || moment().subtract(30, 'days').toDate() >= lastAppRatingPromptAt)
                        );
                    });
            },

            getAppRatingPromptCounter() {
                const deferred = $q.defer();

                if (this.supportsNativeStorage()) {
                    $window.NativeStorage.getItem(
                        this.APP_RATING_PROMPT_COUNTER,
                        counter => {
                            deferred.resolve(counter);
                        },
                        error => {
                            // See https://github.com/TheCocoaProject/cordova-plugin-nativestorage#error-codes
                            // for a list of all supported error codes for cordova-plugin-nativestorage
                            if (error.code === 2) {
                                // when ITEM_NOT_FOUND
                                deferred.resolve(0);
                            } else {
                                deferred.reject(error);
                            }
                        },
                    );
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            },

            setAppRatingPromptCounter(counter) {
                const deferred = $q.defer();

                if (this.supportsNativeStorage()) {
                    $window.NativeStorage.setItem(
                        this.APP_RATING_PROMPT_COUNTER,
                        counter,
                        () => {
                            deferred.resolve();
                        },
                        error => {
                            deferred.reject(error);
                        },
                    );
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            },

            incrementAppRatingPromptCounter() {
                return this.getAppRatingPromptCounter()
                    .then(counter => {
                        counter += 1;
                        return this.setAppRatingPromptCounter(counter);
                    })
                    .catch(() => {});
            },

            getLastAppRatingPromptAt() {
                const deferred = $q.defer();

                if (this.supportsNativeStorage()) {
                    $window.NativeStorage.getItem(
                        this.LAST_APP_RATING_PROMPT_AT,
                        lastAppRatingPromptAt => {
                            deferred.resolve(new Date(lastAppRatingPromptAt));
                        },
                        error => {
                            // See https://github.com/TheCocoaProject/cordova-plugin-nativestorage#error-codes
                            // for a list of all supported error codes for cordova-plugin-nativestorage
                            if (error.code === 2) {
                                // when not found
                                deferred.resolve(null);
                            } else {
                                deferred.reject(error);
                            }
                        },
                    );
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            },

            setLastAppRatingPromptAt(lastAppRatingPromptAt) {
                const deferred = $q.defer();

                if (this.supportsNativeStorage()) {
                    if (!lastAppRatingPromptAt) {
                        lastAppRatingPromptAt = new Date().getTime();
                    }
                    $window.NativeStorage.setItem(
                        this.LAST_APP_RATING_PROMPT_AT,
                        lastAppRatingPromptAt,
                        () => {
                            deferred.resolve();
                        },
                        error => {
                            deferred.reject(error);
                        },
                    );
                } else {
                    deferred.reject();
                }

                return deferred.promise;
            },
        };
    },
]);
