import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import cacheAngularTemplate from 'cacheAngularTemplate';
import template from 'Onboarding/angularModule/views/onboarding_animated_header.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingAnimatedHeader', [
    function factory() {
        return {
            restrict: 'E',
            scope: true,
            controller: angular.noop,
            controllerAs: 'onboardingAnimatedHeader',
            bindToController: {
                animationName: '@',
                images: '<',
                isActive: '<',
            },
            templateUrl,
        };
    },
]);
