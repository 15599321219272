
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.onboarding = window.Smartly.locales.modules.en.back_royal.onboarding || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_forgot_password = window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_forgot_password || {};
window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_forgot_password = {...window.Smartly.locales.modules.en.back_royal.onboarding.onboarding_hybrid_forgot_password, ...{
    "email_sent": "Email sent",
    "check_email": "Check your email for instructions to reset your password.",
    "back_to_login": "Go back to Login"
}
}
    