import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import template from 'Onboarding/angularModule/views/onboarding_header.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupBrandScopeProperties } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingHeader', [
    '$injector',
    function factory($injector) {
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            templateUrl,
            transclude: true,
            link(scope) {
                const config = ConfigFactory.getSync();
                setupBrandScopeProperties($injector, scope, ['onboardingHeaderLogoClass'], { config });
            },
        };
    },
]);
