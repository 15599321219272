
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.nominations = window.Smartly.locales.modules.en.back_royal.nominations || {};
window.Smartly.locales.modules.en.back_royal.nominations.nominations = window.Smartly.locales.modules.en.back_royal.nominations.nominations || {};
window.Smartly.locales.modules.en.back_royal.nominations.nominations = {...window.Smartly.locales.modules.en.back_royal.nominations.nominations, ...{
    "nominations_title": "Nominations",
    "class_nomination_officer_title": "Scholarship Nomination Program",
    "class_nomination_officer_description": "Each cohort, students and alumni can nominate two peers for {brandName} admission with leadership scholarships. Stay tuned for more details!<br><br>In the meantime, fill in a form below to become a <strong class=\"tw-font-semibold\">Class Nomination Officer</strong> and help promote the initiative among your classmates. You'll receive:",
    "class_nomination_officer_list_item_one": "A global conference fee waiver, up to $700 in value",
    "class_nomination_officer_list_item_two": "Early “Insider Access” to new features and courses",
    "class_nomination_officer_list_item_three": "Up to $500 to host a local in-person {brandName} event",
    "form_section_title": "Apply to become a Class Officer",
    "form_section_submitted_title": "Application Received!",
    "form_section_submitted_description": "Thank you for applying, your submission is now under review by our team."
}
}
    