import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';

angularModule.provider('Onboarding.Modal', function onBoardingModalProvider() {
    this.$get = [
        '$injector',
        $injector => {
            const $animate = $injector.get('$animate');
            const $compile = $injector.get('$compile');
            const $controller = $injector.get('$controller');
            const $http = $injector.get('$http');
            const $q = $injector.get('$q');
            const $rootScope = $injector.get('$rootScope');
            const $templateCache = $injector.get('$templateCache');

            // Get template.
            function getTemplate(options) {
                return options.template
                    ? $q.when(options.template)
                    : $http
                          .get(options.templateUrl, {
                              cache: $templateCache,
                          })
                          .then(response => response.data);
            }

            // Get resolve promises.
            function getResolvePromises(resolves) {
                const promisesArray = [];

                angular.forEach(resolves, value => {
                    if (angular.isFunction(value) || angular.isArray(value)) {
                        promisesArray.push($q.when($injector.invoke(value)));
                    }
                });

                return promisesArray;
            }

            // `Modal` service
            function Modal() {}

            // Adds the `modal` to the DOM and returns a promise that will be
            // resolved or rejected with `Modal.hide()` or `Modal.cancel()`,
            // respectively.
            Modal.prototype.show = function (options) {
                const self = this;

                return this.open(options).then(() => self.deferred.promise);
            };

            // Open modal with given options.
            Modal.prototype.open = function (options) {
                const self = this;

                $q.all([getTemplate(options)].concat(getResolvePromises(options.resolve)));

                this.container = angular.element(options.container || 'body');
                this.deferred = $q.defer();
                this.options = options;

                return getTemplate(options).then(templateAndVars => {
                    // A new scope is created when modal is opened.
                    self.scope = $rootScope.$new();

                    // Modal element object. It uses `onBoardingModalDirective` and all methods
                    // which are directly related with DOM manipulation are handled
                    // there. By doing this we just need to inject an `ONBOARDING-MODAL` DOM
                    // element. A `.onboarding-modal` class was given for style purposes.
                    self.modal = angular.element(
                        `<onboarding-modal class="animated onboarding_modal ${
                            options.classList || ''
                        }"></onboarding-modal>`,
                    );

                    // Optional controller definition.
                    if (options.controller) {
                        let resolver = 1;

                        const modalInstance = {
                            cancel() {
                                return self.cancel();
                            },
                            hide(params) {
                                return self.hide(params);
                            },
                        };

                        const locals = {
                            $scope: self.scope,
                            modalInstance,
                        };

                        angular.forEach(options.resolve, (value, key) => {
                            locals[key] = templateAndVars[resolver++];
                        });

                        // Instantiation of controller. `controllerAs` is an optional
                        // label which overrides the label parsed from the controller.
                        $controller(options.controller, locals, false, options.controllerAs);
                    }

                    self.modal.append(templateAndVars);
                    self.modal = $compile(self.modal)(self.scope);

                    self.container.addClass('onboarding_modal__open');

                    $animate.enter(self.modal, self.container);
                });
            };

            // Removes `modal` from the DOM with `$animate.leave()` and returns a
            // promise when animation is completed.
            Modal.prototype.leave = function () {
                const self = this;

                this.container.removeClass('onboarding_modal__open');

                return $animate.leave(this.modal).then(() => $q.when(self.scope.$destroy()));
            };

            // Removes the `modal` from the DOM and resolves the promise returned
            // from `Modal.show()` with passed `parameters`.
            Modal.prototype.hide = function (params) {
                const self = this;

                this.leave().then(() => {
                    self.deferred.resolve(params);
                });

                return this.deferred.promise;
            };

            // Removes the `modal` from the DOM and rejects the promise returned
            // from `Modal.show()`.
            Modal.prototype.cancel = function () {
                const self = this;

                this.leave().then(() => {
                    self.deferred.reject();
                });

                return this.modal ? this.deferred.promise : $q.reject();
            };

            return new Modal();
        },
    ];
});
