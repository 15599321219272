import angularModule from 'Onboarding/angularModule/scripts/onboarding_module';
import template from 'Onboarding/angularModule/views/onboarding.hybrid.register.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { gsap } from 'FrontRoyalGsap';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('onboardingHybridRegister', [
    '$injector',
    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const scopeTimeout = $injector.get('scopeTimeout');
        const $routeParams = $injector.get('$routeParams');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $window = $injector.get('$window');
        const safeDigest = $injector.get('safeDigest');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                const config = ConfigFactory.getSync();

                // FIXME: We briefly changed this to utilize the brandStyleClass in AppBrandConfig, but realized that
                // here we need to specify 'miyamiya' for Miya Miya, whereas in brandStyleClass we specify 'smartly' for Miya Miya.
                // For now I'm just renaming this local property to avoid confusion. It could be better to specify 'miyamiya'
                // in brandStyleClass and change this logic back to getting the value from there, but that'd require a bunch
                // of style tweaks for places that rely on the current 'smartly' behavior.
                if ($window.CORDOVA?.miyaMiya) {
                    scope.logoClass = 'miyamiya';
                } else if (config.isQuantic()) {
                    scope.logoClass = 'quantic';
                } else {
                    scope.logoClass = 'smartly';
                }

                scope.headerTextKey = 'header_text';
                if ($window.CORDOVA?.miyaMiya) {
                    scope.headerTextKey = 'header_text_miya_miya';
                }
                const balloon = document.querySelectorAll('.hybrid__header__message');
                const balloonText = document.querySelectorAll('.hybrid__header__message__text');
                let balloonTextParagraph = document.querySelectorAll('.hybrid__header__message__text p');
                const loading = document.querySelectorAll('.hybrid__header__loading');
                const form = document.querySelectorAll('.onboarding__form');
                const headerContainer = document.querySelectorAll('.hybrid__header');
                const formContainer = document.querySelectorAll('.onboarding__form');

                scope.startInitialAnimations = () => {
                    $(headerContainer).css({
                        opacity: 0,
                    });

                    $(formContainer).css({
                        opacity: 0,
                    });

                    scopeTimeout(
                        scope,
                        () => {
                            $(headerContainer).css({
                                opacity: 1,
                            });

                            $(formContainer).css({
                                opacity: 1,
                            });
                            const timeline = gsap.timeline({
                                onComplete() {
                                    scope.showFooter = true;
                                    safeDigest(scope);
                                },
                            });
                            balloonTextParagraph = document.querySelectorAll('.hybrid__header__message__text p');
                            timeline.set(balloonTextParagraph, {
                                display: 'none',
                                opacity: 0,
                            });
                            timeline
                                .to(loading, {
                                    duration: 0.6,
                                    opacity: 0,
                                    display: 'none',
                                })
                                .to(
                                    balloon,
                                    {
                                        duration: 0,
                                        // removes jank
                                        width: '70px',
                                    },
                                    '-=0.6',
                                )
                                .to(balloon, {
                                    duration: 0.3,
                                    width: '194px',
                                })
                                .fromTo(
                                    balloonTextParagraph,
                                    {
                                        opacity: 0,
                                        display: 'none',
                                    },
                                    {
                                        duration: 0.3,
                                        opacity: 1,
                                        display: 'inline-block',
                                    },
                                )
                                .from(form, {
                                    duration: 0.3,
                                    y: 400,
                                    opacity: 0,
                                })
                                .from(
                                    form,
                                    {
                                        duration: 0.3,
                                        scale: 0,
                                        ease: 'power2.inOut',
                                    },
                                    '-=0.3',
                                );
                        },
                        0,
                    );

                    scope.animated = true; // for tests
                };

                if ($routeParams.animate) {
                    scope.startInitialAnimations();
                } else {
                    $(loading).hide();
                    $(balloon).css({
                        width: '194px',
                    });
                    $(balloonText).css({
                        display: 'inline-block',
                        width: '100%',
                        height: '100%',
                    });
                    $(balloonTextParagraph).each(function () {
                        $(this).show();
                        $(this).css({
                            display: 'inline-block !important',
                        });
                    });

                    scope.animated = false; // for tests
                    scope.showFooter = true;
                }
            },
        };
    },
]);
