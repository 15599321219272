import 'Authentication/angularModule';
import 'Cohorts/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Translation/angularModule';

export default angular.module('FrontRoyal.Onboarding', [
    'FrontRoyal.Authentication',
    'FrontRoyal.Cohorts',
    'Translation',
    'timeoutHelpers',
    'gajus.swing',
]);
